import React from 'react';
import { isMobile } from 'react-device-detect';
import Desktop from './Desktop';
import MobileLanding from './Mobile';

const ComingSoon: React.FC = () => {
  if (isMobile) {
    return <MobileLanding />;
  }
    return <Desktop />;  
};

export default ComingSoon;
