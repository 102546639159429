import React from 'react';
import Mailchimp from 'react-mailchimp-form';

Mailchimp.defaultProps.styles = {
    ...Mailchimp.defaultProps.styles,
    duplicateMsg: {
        color:  "#ffffff"
      },  
    errorMsg: {
      color: "#ffffff"
    }
  }

const EmailCatcher: React.FC = () => {
    return <>
            <Mailchimp
            action='https://wecliques.us18.list-manage.com/subscribe/post?u=33c5f45b89d86fc1c5288f587&amp;id=32fa9ce538'
            fields={[
            {
                name: 'EMAIL',
                placeholder: 'Email',
                type: 'email',
                required: true
            }
            ]}
            className='email'
            />
    </>
}

export default EmailCatcher;
