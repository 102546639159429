import React from 'react';
import { isIPad13, withOrientationChange } from 'react-device-detect';
import EmailCatcher from '../components/EmailCatcher';
import logoImg from '../../../assets/mobile-logo.svg';
import iconImg from '../../../assets/wecliques-icon.svg';
import joinImg from '../../../assets/join-text.svg';

import './styles.scss';

const MobileLanding: React.FC = ({ isLandscape }: any) => {

    return <div className={`mobile ${isLandscape ? 'portrait' : ''}`}>
        <div className="container">
            <img className={`icon ${isIPad13 ? 'ipad' : ''}`} src={iconImg} />
            <img className="logo" src={logoImg} />
            <div className="email-container">
                <img className="join" src={joinImg} />
                <EmailCatcher />
            </div>
        </div>
    </div>
}

export default withOrientationChange(MobileLanding);
