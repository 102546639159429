import { combineReducers} from 'redux';
import { combineEpics, ActionsObservable, StateObservable } from 'redux-observable';
import { catchError, filter, mapTo } from 'rxjs/operators';
import { IAction } from '../../models';

const pingEpic = (action$: ActionsObservable<IAction>) => action$.pipe(
    filter((action: IAction) => action.type === 'PING'),
    mapTo({ type: 'PONG' })
);

const epics = [ pingEpic ];

// with error handle 
export const rootEpic = (action$: ActionsObservable<IAction>, state$: StateObservable<void>, dependencies: any) => 
    combineEpics(...epics)(action$, state$, dependencies).pipe(
      catchError((error, source) => {
        console.error(error);
        return source;
    })
);

export const rootReducer = combineReducers({});
