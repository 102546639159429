import React from 'react';
import imgBg from '../../../assets/v2-new-description.svg';
import poster from '../../../assets/poster-no-wt-20.jpg';
import joinImg from '../../../assets/join-text.svg';
import EmalCatcher from '../components/EmailCatcher';

import './styles.scss';

const Desktop: React.FC = () => {
  return (
    <div className="desktop">
      <div className="landing">
        <div className="container">
          <img className="bg" src={imgBg} />
          <video poster={poster} controls />
          <div className="email-container">
            <img className="join" src={joinImg} />
            <EmalCatcher />
          </div>
        </div>
        <span>
          <a href="https://brandedsolid.com">Branded SOLID LLC</a> &copy;2020
        </span>
      </div>
    </div>
  );
};

export default Desktop;
